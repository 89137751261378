import React from 'react';
import env from "../helper/env.js";
import EsimComponent from "../components/Services/Esim.js";
import {useTranslation} from "react-i18next";
import { Helmet } from 'react-helmet';

function Esim() {
    const {t} = useTranslation(['translation']);
    
    return (
        <>
            <Helmet><link rel="canonical" href={env.base_url + 'esim'} /> </Helmet>
            <section className="main-section pt-5 pb-5 mb-5">
                <div className="container content-space-2">
                    <div className="row justify-content-center align-items-lg-center">
                        <div className="col-12 mb-7 mb-lg-0 order-2 order-lg-1">
                            <div className="pe-lg-3 mb-7">
                                <h2 className="display-3 text-white mb-3 mb-md-5">{t('esim.banner')}</h2>
                            </div>

                        </div>
                    </div>
                    <div className="border-top border-white-10 my-10"></div>
                    <EsimComponent/>
                </div>
            </section>
        </>
    )
}

export default Esim;