import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Spinner } from 'react-bootstrap';


const GenericModal = ({ show, onClose, title, onSubmit, children, size, btnConfirmacion,
    onSubmittingChange, // Nueva prop para manejar cambios de isSubmitting

}) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controlar el spinner o botón oculto
    // Manejar cambios en isSubmitting
    useEffect(() => {
        if (onSubmittingChange) {
            onSubmittingChange(isSubmitting);
        }
    }, [isSubmitting, onSubmittingChange]); // Ejecutar cada vez que isSubmitting cambie

    const handleSubmit = async () => {
        console.log("Antes de submit: ", isSubmitting); // Verifica el estado inicial
        if (onSubmit) {
            setIsSubmitting(true);
            console.log("isSubmitting después de setState: ", true); // Confirmar el cambio
            try {
                await onSubmit();
            } finally {
                 setIsSubmitting(false);
                console.log("isSubmitting al finalizar: ", false); // Confirmar el cambio
            }
        }
    };
    
    return (
        <Modal show={show} onHide={onClose} size={size} centered>
            {title && (
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
            )}
            <Modal.Body>
                {children} {/* Aquí irá el contenido dinámico */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {t('cancel')}
                </Button>
                {onSubmit && (
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting} // Verifica que este prop sea correcto
                    >
                        {isSubmitting ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="sr-only">{t('loading')}</span>
                            </>
                        ) : (
                            btnConfirmacion
                        )}
                    </Button>
                )}
                {/* {onSubmit && ( // Solo muestra el botón si hay una acción definida
                    <Button variant="primary" onClick={onSubmit}>
                        {btnConfirmacion}
                    </Button>
                )} */}
            </Modal.Footer>
        </Modal>
    );
};

export default GenericModal;