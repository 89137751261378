import React from 'react';
import { Translation } from 'react-i18next';
import { t } from "i18next";
import callServerApi from "../Utils/apiManager";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';
import ResponseAlert from "../Common/ResponseAlert";
import CustomNavDropdown from '../Utils/customNavDropdown.js';

import helper from "../../helper/fn.js";


class RechargeRequest extends React.Component {
    constructor(props) {
        console.log(props);
        super(props);
        this.state = {
            loading: false,
            responseError: '',
            responseSuccess: '',
            phoneNumber: '',
            prefix: '',
            defaultCountry: props.defaultCountry || null, countryCode: '',
            name: '', email:'',provider_id:null,
            clientFound:false,
            validatedRequest: false, 
            requireProvider: (props.requireProvider !== "no"),
            providersData:null, providerSelected:null,providerDropdownSelected:null,

            codAffi: props.codAffi || '',
        };
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
    }

    componentDidMount() {
        const { defaultCountry } = this.state;
        if (!defaultCountry) {
            this.setState({ defaultCountry: helper.getCurrentCountry() || 'ES' }); // País por defecto en caso de error
        }
      }

    handlePhoneChange = (value) => {
        let countryPrefix = '',
            countryCode = '' ;
        if (value && value.length > 4) {
            try {
                const phoneNumber = parsePhoneNumberFromString(value);
                if (phoneNumber) {
                    countryPrefix = `+${phoneNumber.countryCallingCode}`;
                    countryCode = phoneNumber.country;
                }
            } catch (error) {
                console.error('Error obteniendo el prefijo del país:', error);
            }
        }

        this.setState({ phoneNumber: value, prefix: countryPrefix, countryCode: countryCode });
    };

    handleNameChange = (e) => {
        this.setState({ name: e.target.value });
    };
    handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    };
    handleSubmit = (e) => {
        e.preventDefault();
        const {phoneNumber,validatedRequest} = this.state;
        if (!phoneNumber) {
            this.setState({ responseError:  t('form.all fields are required')});
            return;
        }
        this.setState({loading:true});
        if (!validatedRequest){
            this.validateRequest();
        }else{
            this.createRequest();
        }
    };
    
    /* first request -> return found or not found */
    validateRequest = () =>{
        const { phoneNumber, requireProvider} = this.state;
        callServerApi('ws/recharge-request', 'POST', {phoneNumber})
            .then((result) => {
                this.setState({ validatedRequest: true});
                if (result.data && result.data.email) { //clientFound
                    this.setState({clientFound:true, email:result.data.email, validateRequest:true});
                    this.createRequest(result.data);
                }else{
                    if (requireProvider) this.getProviders();
                    else this.setState({loading:false, validateRequest:true});
                }
            })
            .catch((error) => {
                this.setState({
                    responseError: error.data.error || 'An error occurred.',
                    loading: false,
                });
                setTimeout(() => this.setState({ responseError: '' }), 5000);
                this.reload();
            });
    }
    /* create final request  */
    createRequest = (data) =>{
        const { phoneNumber, prefix, countryCode, name, email, provider_id, codAffi } = this.state;

        if (!email && !data && !data.email) { //email is required
            this.setState({ loading:false, responseError: t('form.all fields are required') });
            return;
        }
        const oToSend = {phoneNumber:phoneNumber, prefix:prefix, countryIsos:countryCode, name:name};
        oToSend.email = email || data.email; //user input or data from validateRequest
        if (provider_id || (data && data.provider_id)) {
            oToSend.provider_id = provider_id || data.provider_id;
        } //user input or data from validateRequest
        if (codAffi) oToSend.cod_affi = codAffi; //codigo de afiliado

        callServerApi('ws/create-recharge-request', 'POST', oToSend)
            .then((result) => {
                if (result.status === 'ok') {
                    this.setState({loading:false, responseSuccess: result.message});
                    setTimeout(() =>{ this.setState({responseSuccess:''})}, 7000);
                }
                if (result.status === 'ko') {
                    this.setState({loading:false, responseError: result.error});
                    setTimeout(() =>{ this.setState({responseError:''})}, 7000);
                }
                this.reload();
            })
            .catch((error) => {
                this.setState({
                    responseError: error.data.error || 'An error occurred.',
                    loading: false,
                });
                setTimeout(() => this.setState({ responseError: '' }), 5000);
                this.reload();
            });
    }
    handleSelectProvider = (selected) => {
        const filtered = this.state.providersData.filter(option =>
            option.id === selected.id
        );
        if (filtered && filtered.length > 0){
            this.setState({provider_id:filtered[0].id});
        }
    }
   
    getProviders = () => {
        const {countryCode} = this.state;
        callServerApi('get-providers?countryIsos='+countryCode, 'GET')
        .then(result => {
            if (result.status === 'ok' && result.data && result.data.length > 0){
                this.setState({ providersData: result.data, loading:false, validateRequest:true});
            }else{  //no providers found    
                this.setState({ responseError: t('recharges.The phone number format is invalid'), loading:false });
                setTimeout(() =>{ this.setState({responseError:''})}, 5000);
            }
        })
        .catch(error => {
            this.handleError(error.message);
            this.setState({ responseError: error.message, loading:false });
            setTimeout(() =>{ this.setState({responseError:''})}, 5000);
        });
       
    }
    reload =() =>{
        this.setState({
            phoneNumber:'',
            name: '',
            email:'',
            countryIsos:'',
            prefix:'',
            loading: false,
            validatedRequest: false,
            clientFound:false,
        });
    }
    render() {
        const { responseSuccess, responseError, loading,
            defaultCountry, requireProvider, providersData,
            phoneNumber, name, email, validatedRequest, clientFound} = this.state;

        return (
            <Translation ns={['translation']}>
                {(t) => (
                    <Form id="recharge-request" onSubmit={this.handleSubmit}>

                        {responseSuccess && (
                            <ResponseAlert response={responseSuccess} variant="success" />
                        )}
                        {responseError && (
                            <ResponseAlert response={responseError} variant="danger" />
                        )}
                        <input type="hidden" name="codAffi" value={this.state.codAffi} />
                        <div className="row">
                            <div className="col-12">
                                <InputGroup>
                                    <Form.Control autoFocus
                                        className="form-control PhoneInput"
                                        name="name"
                                        placeholder={t('contact.name')}
                                        required
                                        value={name}
                                        onChange={this.handleNameChange}
                                        disabled={loading}
                                    />
                                </InputGroup>
                            </div>

                           
                            <div className="col-12 col-lg-7 mb-3">
                                {!validatedRequest &&
                                    <PhoneInput
                                        placeholder={t('form.enter phone number')}
                                        value={phoneNumber}
                                        onChange={this.handlePhoneChange}
                                        className="form-control"
                                        defaultCountry={defaultCountry}
                                        international
                                        disabled={loading}
                                    />
                                }
                            </div>

                            {/**client no fount */}
                            {validatedRequest && !clientFound && <>
                                <div className="col-12 mb-3">
                                    <p className='text-secondary lh-base fs-5'>
                                        {t('rechargeRequest.clientNofound1')}
                                        <b> {phoneNumber} </b>
                                        <br/>{t('rechargeRequest.clientNofound2')}
                                    </p>
                                </div>
                                <div className="col-12 mb-3">
                                    <InputGroup>
                                        <Form.Control
                                            className="form-control PhoneInput"
                                            name="email"
                                            placeholder={t('register.email')}
                                            required
                                            value={email}
                                            onChange={this.handleEmailChange}
                                            disabled={loading}
                                        />
                                    </InputGroup>
                                </div>   

                                {requireProvider && providersData  && 
                                    <div className="col-12 mb-3">
                                        <div className='mt-4 mb-4'><h4>{t('recharges.select the provider')}</h4></div>
                                        <CustomNavDropdown id="providers" items={providersData} itemkey="id" itemsearch={'name'} cols={['logo', 'name']}
                                            onSelect= {this.handleSelectProvider}/>
                                    </div>
                                }

                            </>}

                            <div className={`col-12 mb-3 text-center ${!validatedRequest ? "col-lg-5" : ""}`}>
                                <button type="submit" className="btn btn-primary btn-style" disabled={loading}>
                                  {loading ? (
                                    <>
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        &nbsp;{t('sending')}...
                                    </>
                                 ) : (
                                    t('next')
                                )}
                                </button>
                                {validatedRequest && !clientFound && <>
                                    <button type="button" className="btn btn-secondary btn-style m-1" onClick={this.reload}>
                                    {t('cancel')}
                                </button>
                                </>}
                            </div>


                        </div>
                    </Form>
                )}
            </Translation>
        );
    }
}

export default RechargeRequest;
