import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { t } from "i18next";
import { useNavigate } from 'react-router-dom';
import { Loading } from '../Common/Loading.js';
import GenericModal from '../Common/GenericModal';
import callServerApi from '../Utils/apiManager';
import ResponseAlert from '../Common/ResponseAlert.js';
import AutocompletePhoneNumber from '../Utils/autocompletePhoneNumber.js';
import CustomNavDropdown from '../Utils/customNavDropdown.js';
import CustomCarousel from '../Utils/customCarousel.js';

import helper from "../../helper/fn.js";
import { Button, Nav, ProgressBar, Form} from 'react-bootstrap';

class Recharge extends Component {
    constructor(props){
        super(props);
        this.state = {
            step:1,
            loading:false,
            btnTitle:'Recharge',
            title:'Ready to send a top-up?',
            responseError: '', responseSuccess: '', responseErrorModal: '',
            prefixesData:null, prefixSelected: null ,
            phoneNumber:'', confirmNumber:false,
            providersData:null, providerSelected:null,providerDropdownSelected:null,
            productsData:null, productsSelected:null, productSelected:null,productDropdownSelected:null,
            bonusData:null,beneficiaryData:null, beneficiaryNewName:'', 
            rechargeTypeData:null, rechargeTypeSelected:null,rechargeTypeDropdownSelected:null, //instant or not instant
            orderData:null,orderMode:null,
            pay: null, orderId:null,
            responseCheckout: null, //for init payment
            paymentProgress: 70,
            showModal: {},
            voucherCode: '',voucherCodeValid: false,
        }
        this.payformRef = React.createRef();
        // this.beneficiaryName = React.createRef();
    }
    componentDidMount() {   
        const beneficiaryData = localStorage.getItem('beneficiaryObj');
        if (beneficiaryData){
            this.setState({beneficiaryData: JSON.parse(beneficiaryData)});
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { prefixSelected, phoneNumber, providerSelected } = this.state;
        // Actualización de prefixSelected
        if (prefixSelected !== prevState.prefixSelected) {
            if (prefixSelected) {
                helper.setPhoneNumberToStorage(prefixSelected, phoneNumber)
            } else { // prefixSelected === null
                helper.clearClientStorage();
            }
        }
        // Actualización de phoneNumber si prefixSelected está definido
        if (prefixSelected && phoneNumber !== prevState.phoneNumber) {
            if (phoneNumber) {
                helper.setPhoneNumberToStorage(prefixSelected, phoneNumber)
            } else {
                localStorage.removeItem('phoneNumber');
                localStorage.removeItem('providerObj');
            }
        }
        // Actualización de providerSelected
        if (providerSelected && providerSelected !== prevState.providerSelected) {
            localStorage.setItem('providerObj', JSON.stringify(providerSelected));
            if(providerSelected.provider_code)  this.getProduct(providerSelected.provider_code);
        }
    }

    /*
        oData = {prefixSelected, phoneNumber, providers}
        @AutocompletePhoneNumber
    */
    handlePhoneNumberResult = (oData) => {
        if (oData.prefixSelected && oData.phoneNumber && oData.providers){
            this.setState({loading:false, prefixSelected:oData.prefixSelected , phoneNumber:oData.phoneNumber});
            this.setState({providersData: oData.providers, step:2, title:'recharges.Shipping information'});
        }else if (oData.prefixSelected && oData.phoneNumber && oData.providerSelected){
            this.setState({
                loading:false, prefixSelected:oData.prefixSelected , phoneNumber:oData.phoneNumber,
                providerSelected: oData.providerSelected, title:'recharges.Shipping information',
                step:3
            });
        }
    }
    /* ------------------------
    * PROVIDERS
    *------------------------*/
    reloadProvider = () => {
        const {providersData} = this.state;
        this.setState({providerSelected:null, step:2, loading:false});
        localStorage.removeItem('prefixObj');
        if (!providersData) {
            this.getProviders();
        }
    }
    getProviders = () => {
        const {prefixSelected, phoneNumber} = this.state;
        if (!prefixSelected) { this.setState({step:1, phoneNumber:null,prefixSelected:null,confirmNumber:false}); return false; }
        if (!phoneNumber) {  this.setState({step:1, phoneNumber:null,confirmNumber:false}); return false; }
        this.setState({loading:true});
        callServerApi('get-providers?countryIsos='+prefixSelected.country_iso, 'GET')
          .then(result => {
            if (result.status === 'ok' && result.data && result.data.length > 0){
                const providerObjString = localStorage.getItem('providerObj');
                if (providerObjString) {
                    const providerObj = JSON.parse(providerObjString);
                    const providerCode = providerObj.provider_code;

                    result.data.sort((a, b) => {
                        return a.provider_code === providerCode ? -1 : b.provider_code === providerCode ? 1 : 0;
                    });
                }
              this.setState({providersData:result.data, loading:false});
            }else{
                this.handleError(result.message);
            }
          })
          .catch(error => {
            this.handleError(error.message);
          });
    }
    handleSelectProvider = (selected) => {
        const filtered = this.state.providersData.filter(option =>
            option.id === selected.id
        );
        if (filtered && filtered.length > 0){
            this.setState({providerDropdownSelected:filtered[0]});
        }
    }
    setProviderSelected = () => {
        const { providersData, providerDropdownSelected } = this.state;
        let selected = null;
        if (providerDropdownSelected){ selected =  providerDropdownSelected;
        }else if (providersData && providersData.length > 0) selected = providersData[0];
        this.setState({providerSelected: selected,  step:3});
        //this.getProduct(selected.provider_code);
    }
    /* ------------------------
    * 3 RECHARGE IMPORT *AUTH
    *------------------------*/
    getProduct= (provider_code) => {
        const token = localStorage.getItem('token');
        if (!token) {
            localStorage.setItem('from', 'recharge');
            this.props.navigate('/signin');  // Redirigir a la página de inicio de sesión
        } else {
            this.setState({loading:true});
            callServerApi('get-product', 'GET', {providerCode:provider_code}, true)
            .then(result => {
                if (result.status === 'ok' && result.data && result.data.topUp && result.data.topUp.length){
                    this.setState({loading:false,step:3,
                        productsData: (result.data.topUp && result.data.topUp.length) ? result.data.topUp : null,
                        bonusData: (result.data.bonus && result.data.bonus.length) ? result.data.bonus : null,
                        productsSelected:null
                    });
                } else {
                    let error = result.message || result.error;
                    if (result.data.topUp && result.data.topUp.length === 0){ error = t('recharges.no products available'); }
                    this.handleError(error);
                }
            })
            .catch(error => {
                this.handleError(error.message);
            });
        }
    }
    handleSelectProduct = (selected) => {
        const filtered = this.state.productsData.filter(option =>option.id === selected.id);
        if (filtered && filtered.length > 0){
            this.setState({productDropdownSelected:filtered[0]});
        }

    }
    setProductSelected = ()=> {
        const { productsData, productDropdownSelected } = this.state;
        let productsSelected = null;
        if (productDropdownSelected){ productsSelected =  productDropdownSelected;
        }else if (productsData && productsData.length > 0) productsSelected = productsData[0];
        //instant or not instat rechargeSelected:null,rechargeTypeData:null,

        const rechargeTypeData = [];
        let o = null;
        if (productsSelected && productsSelected.send_value && productsSelected.send_value.instant){
            o =  productsSelected.send_value.instant;
            o.id = 'instant'; o.name=t('recharges.instantpay'); o.amount = String(o.value) + ' ' + o.currency;
            rechargeTypeData.push(o);
        }
        if (productsSelected && productsSelected.send_value && productsSelected.send_value.not_instant){
            o =  productsSelected.send_value.not_instant;
            o.id = 'not_instant'; o.name=t('recharges.not_instantpay'); o.amount = String(o.value) + ' ' + o.currency;
            rechargeTypeData.push(o);
        }
        this.setState({productsSelected: productsSelected, rechargeTypeData:rechargeTypeData });
    }
    handleSelectBonus = (selected) => {
        const filtered = this.state.bonusData.filter(option =>option.id === selected.id);
        if (filtered && filtered.length > 0){
            const bonus = filtered[0];
            const rechargeTypeData = [];
            let o = null;
            if (bonus.send_value && bonus.send_value.instant){
                o =  bonus.send_value.instant;
                o.id = 'instant'; o.name=t('recharges.instantpay'); o.amount = String(o.value) + ' ' + o.currency;
                rechargeTypeData.push(o);
            }
            if (bonus.send_value && bonus.send_value.not_instant){
                o =  bonus.send_value.not_instant;
                o.id = 'not_instant'; o.name=t('recharges.not_instantpay'); o.amount = String(o.value) + ' ' + o.currency;
                rechargeTypeData.push(o);
            }
            this.setState({productsSelected: bonus, rechargeTypeData:rechargeTypeData });
        }
    }


    handleSelectRechargeType = (selected) => {
        const filtered = this.state.rechargeTypeData.filter(option =>
            option.id === selected.id
        );
        if (filtered && filtered.length > 0){
            this.setState({rechargeTypeDropdownSelected:filtered[0]});
        }
    }
    chooseRechargeType=()=> {
        const { productsSelected, rechargeTypeData, rechargeTypeDropdownSelected } = this.state;
        let selected = null;
        if (rechargeTypeDropdownSelected){ selected =  rechargeTypeDropdownSelected;
        }else if (rechargeTypeData && rechargeTypeData.length > 0) selected = rechargeTypeData[0];
        this.setOrderData(productsSelected, selected, selected.id);
    }

    setOrderData = (product, send_value, mode) => {
        const {prefixSelected, phoneNumber, providerSelected} = this.state;
        const orderData = {
                "product_id": product.id,
                "concept_id": 1,//Default,
                "phone_number" : helper.patterPhoneNumberToSend(prefixSelected.value, phoneNumber), //prefixSelected.rlabel + phoneNumber,
                "provider_code": providerSelected.provider_code,
                "sku_code": product.sku_code,
                "localization_key": product.localization_key,
                "receive_value": product.receive_value,
                "receive_currency": product.receive_currency,
                "send_value": send_value.value,
                "send_currency": send_value.currency,
                "processing_mode": mode,
                "redemption_mechanism": "Immediate",
                "quantity": 1,
                "price": send_value.value,
                "finalAmount": send_value.value,
        };
        this.setState({
            title: 'recharges.Shipping information',
            orderData:orderData, orderMode:mode,
            step:4
        });
    }

    sendOrder = () => {
        const {orderData, beneficiaryNewName, voucherCode,voucherCodeValid} = this.state;
        const ko = () =>   this.setState({loading:false, step:4, orderId:null});
        if (!orderData) {  ko(); return; }
        this.setState({step:5, pay:true});
        if (beneficiaryNewName && beneficiaryNewName.trim() !== "") {
            this.setState({beneficiaryData:{name:beneficiaryNewName}}, () => {
                this.saveNewBeneficiary();
            });
        } 
       
        //remove localstorage
        const data = {
            total:orderData.price || 0,
            details: [orderData]
        };
        if (voucherCode && voucherCode.trim() !== "" && voucherCodeValid) {
            data.discount_code = voucherCode;
        }    
        //console.log('step',this.state.step);
        callServerApi('new-order', 'POST', data, true)
          .then(result => {
            if (result.status === 'ok' && result.data && result.data.order_id){
                this.setState({pay:true, orderId:result.data.order_id, paymentProgress: 80});
                ///checkout, el react envia order_id y locale, backend retorna data.url_checkout
                this.initCheckOut(result.data.order_id);
            }else{
                this.handleError(result.message);
                ko();
            }
          })
          .catch(error => {
            this.handleError(error.message);
            ko();
          });
    }
    initCheckOut = (orderId) => {
        const ko = () =>   this.setState({loading:false, step:4, orderId:null});
        if (!orderId) { ko(); return; }

        callServerApi('checkout', 'POST', {order_id:orderId}, true)
          .then(result => {
            this.setState({loading:false});
            if (result.status === 'ok' && result.data){
                if (result.data.url_checkout) window.location.href = result.data.url_checkout; //redirect to url
                else {
                    this.setState({responseCheckout: result.data, paymentProgress: 85 });
                    setTimeout(() =>  { this.setState({paymentProgress: 90 }) }, 500);
                    setTimeout(this.triggerPaymentSubmit, 1000);
                }
                helper.clearClientStorage();
            }else{
                this.handleError(result.message);
                ko();
            }
          })
          .catch(error => {
            this.handleError(error.message);
          });
    }
    triggerPaymentSubmit = () =>{ console.log('triggerPaymentSubmit', this.payformRef);
        this.setState({paymentProgress: 95 });
        if (this.payformRef.current) {
            this.payformRef.current.submit();  // Dispara el submit del formulario
        }
    }
    resetOrder = () =>{
        this.setState({productsSelected:null, step:3, loading:false});
    }
    handleInputBeneficiaryName = (event) => {
        this.setState({ beneficiaryNewName:  event.target.value});
    } 
    handleSubmitBeneficiaryName  = async () => {
        const {beneficiaryNewName} = this.state; 
        const ko = (message) => {
            this.setState({ responseErrorModal: message ||  t("form.required")});
            setTimeout(() =>  { this.setState({responseErrorModal: '' }) }, 1000);
        }        
        if (beneficiaryNewName && beneficiaryNewName.trim() !== "") {
            this.handleCloseModal('beneficiaryName') ;  
            this.setState({responseErrorModal: '' });
        } else{
            ko();
            return;
        } 
    }
    saveNewBeneficiary = () =>{
        const {prefixSelected, phoneNumber,providerSelected,beneficiaryNewName} = this.state;
        const d = {
            name: beneficiaryNewName,//this.beneficiaryName.current.value,
            countryIsos: (this.state.prefixSelected) ? this.state.prefixSelected.country_iso : '',
            phone: helper.patterPhoneNumberToSend(prefixSelected.value, phoneNumber),
            providerCode: providerSelected.provider_code,
        }
        callServerApi('client/new-beneficiaries', 'POST', d, true)
        .then(result => {
            console.debug(result);
        }).catch(error => {
            console.debug(error);
        });
    }

    handleError(message) {
        this.setState({ responseError: message || '', loading: false });
        setTimeout(() => { this.setState({ responseError: '' }); }, 5000);
    }

    handleCheckboxChange = (event) => {
        this.setState({ showBeneficiaryName: event.target.checked });
        if (this.beneficiaryName && this.beneficiaryName.current){  this.beneficiaryName.current.focus(); }
    };

    handleShowModal = (target) => {
        const updatedShowModal = { ...this.state.showModal, [target]: true };
        this.setState({ showModal: updatedShowModal });
        
    };
    handleCloseModal = (target) => {
        const updatedShowModal = { ...this.state.showModal, [target]: false };
        this.setState({ showModal: updatedShowModal });
    };
    
    handleInputVoucherCode = (event) => {
        this.setState({ voucherCode: event.target.value });
    };

    handleVoucherCode = async () => {
        const { orderData, voucherCode } = this.state;
        const ko = (message) => {
            this.setState({ responseErrorModal: message || t("voucher.the coupon code is mandatory")});
            setTimeout(() =>  { this.setState({responseErrorModal: '' }) }, 1000);
        }    
    
        if (!voucherCode || voucherCode.trim() === "") {
            ko();
            return;
        }
    
        const data = {
            code: voucherCode,
            amount: orderData.price,
        };
    
        try {
            const result = await callServerApi("voucher-code", "POST", data, true);
            console.log("Respuesta completa del servidor:", result); // Depuración
    
            if (result.status === "ok" && result.data) {
                if (Number(result.data.new_amount) > -1) {
                    this.setState({
                        orderData: { ...orderData, finalAmount: Number(result.data.new_amount) },
                        voucherCodeValid: true,
                        responseErrorModal: '',
                    });
                    this.handleCloseModal('cupon');
                }else{
                    ko(result.message);
                }
            } else {
                const message = result.message || result.error;
                ko(message);
                return ; 
            }
        } catch (error) {
            ko(error.message);
            return; 
        }
    };
    

    render(){
            const { loading,  title, btnTitle, step,  responseError,beneficiaryData,
                prefixSelected, phoneNumber, providersData, providerSelected,
                productsData, productsSelected, bonusData,
                rechargeTypeData, orderData, orderMode, pay, responseCheckout, voucherCode, voucherCodeValid, showModal,
                responseErrorModal }  = this.state;
        return (
        <Translation ns={['translation']}>
        { (t) =><>
            {loading && <Loading/> }

            {!loading &&
            <div className="box-wrap">
                {step > 1 && <div className='refresh-link'>
                    <Nav activeKey="/recharge">
                        <Nav.Item>
                            <Nav.Link href="/recharge" onClick={helper.clearClientStorage}><i className="fa fa-angle-left" aria-hidden="true"></i> {t("recharges.Start a new recharge")} </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>}
                {responseError && <ResponseAlert response={responseError} variant="danger" /> }

                {step === 1 && <div>

                    {(!prefixSelected || !phoneNumber) && <>
                        <div className="info text-center mb-4"> <h4 className="title-box">{t(title)}</h4></div>
                        <AutocompletePhoneNumber placeholder={t('recharges.where are you sending to')} btnTitle={btnTitle} handleResult={this.handlePhoneNumberResult}/>
                    </> }

                </div> }
                {step > 1 && title && <div className='text-center border-bottom'>
                    <h4 className="title-box">{t(title)}</h4>
                </div>}
                <div className='row'>  
               
                {step > 1 && phoneNumber &&  
                <div data-step={step} data-target="prefixSelected & providerSelected" className='col-12'>
                    <div className='row justify-content-between mt-4 flex-wrap pb-4 border-bottom'>    
                        <div className='col-10 col-lg-5 text-left'>
                            <img className="thumbnail-image mini mr-4 rounded-circle "
                                src={prefixSelected.img}
                                alt={prefixSelected.label}
                            />
                            <b>{prefixSelected.rlabel} {phoneNumber}</b>
                        </div>
                        {providerSelected && <>                        
                            <div className='col-10 col-lg-5 text-start text-lg-end'>
                                { providerSelected.logo && <img className="thumbnail-image mini mr-4 rounded-rectangle" 
                                    src={providerSelected.logo}
                                    alt={providerSelected.name}
                                />}
                                    <b>{providerSelected.name}</b>
                            </div>
                        </>}    
                        
                        {!pay && <div className='col-2 text-end'>
                            <button className='receipe btn btn-sm lh-1 btn-trans text-primary w-100 text-end'
                                onClick={()=>{
                                    this.setState({step:1, phoneNumber:null,confirmNumber:false});
                                }}>
                                <i className='fa fa-lg fa-pencil'/>
                            </button>
                        </div>}
                        {pay && <div className='col-2'> &nbsp;</div>}
                    </div>    
                </div>
                }

                {step === 2 && providersData && providersData.length &&
                    <div data-step={step} >
                        <div className='mt-4 mb-4'><h4>{t('recharges.select the provider')}</h4></div>
                        <CustomNavDropdown id="providers" items={providersData} itemkey="id" itemsearch={'name'} cols={['logo', 'name']}
                            onSelect= {this.handleSelectProvider}/>
                        <div className='d-flex justify-content-center'>
                            <Button type="button" className='btn-style btn-primary' onClick={this.setProviderSelected}> {t('next')}</Button>
                        </div>

                    </div>
                 }
            

                {step === 3 && !productsSelected &&
                    <div data-step={step} >
                        {productsData && productsData.length && <>
                        <div className='mt-4 mb-4'><h4>{t('home.select top-up amount')}</h4></div>
                        <CustomNavDropdown id="products" items={productsData} itemkey="id" itemsearch={'receive_value'} cols={['receive_value', 'receive_currency']}
                            onSelect= {this.handleSelectProduct}/>
                        </>}

                        {/* BONUS */}
                        {bonusData && bonusData.length && <>
                            <div className='mt-4 mb-4'><h4>{t('recharges.You can also select one of these bonuses')}</h4></div>
                            <CustomCarousel id="bonus" items={bonusData} provider={providerSelected} itemkey="id"
                            onSelect= {this.handleSelectBonus}/>
                        </>}

                        <div className='mt-4 d-flex justify-content-center'>
                            <Button type="button" className='btn-style btn-secondary' onClick={this.setProductSelected}> {t('next')}</Button>
                        </div>
                    </div>
                }
                {step === 3 && productsSelected &&
                    <div  data-step={step} data-target="productsSelected" className='col-12 mt-4 mb-4  pb-4 border-bottom'>  {/* instant or not instant */}
                        <div className='row justify-content-between'>
                            <div className='col-12 text-left lh-1'> {t('recharges.Recharge amount')}: </div>
                            <div className='col-10 h4 text-primary font-weight-bold text-left mt-2'>  {productsSelected.receive_value} {productsSelected.receive_currency}</div>
                            {!pay && (
                                (Array.isArray(productsData) && productsData.length > 1) ||
                                (Array.isArray(bonusData) && bonusData.length > 1)
                            ) &&  <div className='col-2 text-end'>
                                <button className='receipe btn btn-sm lh-1 btn-trans text-primary w-100 text-end'
                                    onClick={()=>{
                                        this.setState({step:3, productsSelected:null});
                                    }}>
                                    <i className='fa fa-lg fa-pencil'/>
                                </button>
                            </div>}
                            {pay && <div className='col-2'> &nbsp;</div>}
                        </div>
                        {productsSelected.observation && <div className='col-12 text-center bg-style mt-4'> <div className='small  text-white'> {productsSelected.observation} </div></div>}

                        <div className='mt-3 mb-2 text-left'>{t('recharges.Amount to pay')}:</div>
                        <CustomNavDropdown id="recharge" items={rechargeTypeData} itemkey="id" itemsearch={'amount'} cols={['name', 'amount']}
                            onSelect= {this.handleSelectRechargeType}/>
                    
                    
                        <div className='d-flex justify-content-center'>
                            <Button type="button" className='btn-style btn-secondary' onClick={this.chooseRechargeType}> {t('next')}</Button>
                        </div>
                    </div>
                }
                {/**step 4 */}
                {step > 3 && orderData && <>

                     {/*Amount recharge*/}
                    <div data-step={step} data-target="Amount recharge" className='col-12 '>
                        <div className='row justify-content-between mt-4 flex-wrap pb-4 border-bottom'>
                            <div className='col font-weight-bold text-left lh-1'> {t('recharges.Recharge amount')}: </div>
                            <div className='col h4 text-primary font-weight-bold text-end'>  {orderData.receive_value} {orderData.receive_currency}</div>
                            {productsSelected.observation && <div className='col-12 h6 text-primary font-weight-bold text-end'>  {productsSelected.observation}</div>}
                        </div>    
                    </div>

                    {/*Amount to pay*/}

                    <div data-step={step} data-target="Amount to pay" className='col-12 '>
                        <div className='row justify-content-between mt-4 flex-wrap pb-4 border-bottom'>
                            <div className='col font-weight-bold text-left lh-1'>{t('recharges.Amount to pay')}: </div>
                            <div className='col h4 text-primary font-weight-bold text-end'> {orderData.finalAmount} {orderData.send_currency}
                                {orderMode === 'instant' && <div className="h6"><b>{t('recharges.instant pay')}</b></div>}
                                {orderMode === 'not_instant' && <div  className="h6"><b>{t('recharges.no instant pay')}</b></div>}
                            </div>
                        </div>      
                    </div>

                     {/**cupon */}  
                     {!voucherCodeValid &&<>
                        <div data-step={step} className='col-12'>
                            <div className='row justify-content-between mt-4 flex-wrap pb-4 border-bottom'>

                                <div className='col-9 text-left'>
                                    <b>{t('recharges.do you have a coupon code')}</b>
                                </div>
                                <div className='col-3 text-end'>
                                    <button className='btn btn-sm lh-1 btn-primary btn-color-gray text-white w-100 text-uppercase'
                                        onClick={() => this.handleShowModal('cupon')} >
                                        {t('recharges.redeem coupon')}
                                    </button>
                                </div>
                            </div>
                        </div>
  
                        <GenericModal
                            show={showModal.cupon}
                            btnConfirmacion= {t('voucher.redeem')}
                            onSubmit={() => this.handleVoucherCode() }
                            onClose={() => this.handleCloseModal('cupon') }
                            title={t('voucher.enter your voucher code to redeem it')}
                            size="lg"
                        >
                            
                            {/* Here you dynamically define the entire content of the body. */}
                            <div>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={voucherCode}
                                    onChange={this.handleInputVoucherCode}
                                    placeholder={t('voucher.enter your voucher code to redeem it')}
                                />
                                {responseErrorModal && (
                                    <div className="text-danger mt-2">
                                        {responseErrorModal}
                                    </div>
                                )}
                            </div>
                        </GenericModal>
                    </>}
                    {voucherCodeValid && <>
                    <div data-step={step} className='col-12'>
                        <div className='row justify-content-between mt-4 flex-wrap pb-4 border-bottom'>
                            <div className='col font-weight-bold text-left lh-1'>{t('voucher.discount coupon applied')}: </div>
                            <div className='col h4 text-primary font-weight-bold text-end'> {voucherCode}</div>
                        </div>    
                    </div>
                    </>}   


                    {/**beneficiary */} 
                    
                    {!beneficiaryData && <>
                        <div data-step={step} className='col-12'>
                            <div className='row justify-content-between mt-4 flex-wrap pb-4 border-bottom'>
                                <div className='col-9 text-left'>
                                    <b>{t('recharges.save the beneficiary name for your next recharges')}</b>
                                </div>
                                <div className='col-3 text-end'>
                                    <button className='btn btn-sm lh-1 btn-primary btn-color-gray text-white w-100'
                                        onClick={() => this.handleShowModal('beneficiaryName')} >
                                    { !this.state.beneficiaryNewName && <>{t('save')}</> }
                                    { this.state.beneficiaryNewName  && <>{this.state.beneficiaryNewName}</> }
                                    </button>
                                </div>
                            </div>    
                        </div>

                        <GenericModal
                            show={showModal.beneficiaryName}
                            btnConfirmacion= {t('save')}
                            onSubmit={() => this.handleSubmitBeneficiaryName() }
                            onClose={() => this.handleCloseModal('beneficiaryName') }
                            title={t('recharges.save the beneficiary name for your next recharges')}
                            size="lg"
                        >
                            
                            {/* Here you dynamically define the entire content of the body. */}
                            <div>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={this.state.beneficiaryNewName}
                                    onChange={this.handleInputBeneficiaryName}
                                    placeholder={t('recharges.save the beneficiary name for your next recharges')}
                                />
                                {responseErrorModal && (
                                    <div className="text-danger mt-2">
                                        {responseErrorModal}
                                    </div>
                                )}
                            </div>
                        </GenericModal>
                    </>}
                    {beneficiaryData && beneficiaryData.name && <>
                    <div data-step={step} className='col-12'>
                        <div className='row justify-content-between mt-4 flex-wrap pb-4 border-bottom'>
                            <div className='col font-weight-bold text-left lh-1'>{t('beneficiary')}: </div>
                            <div className='col h4 text-primary font-weight-bold text-end'> {beneficiaryData.name}</div>
                        </div>    
                    </div>
                    </>}   
    


   
                   

                    {/*---------------------- */}


                    {step < 5 && <div data-step={step} className='col-12 text-center mt-5 d-lg-flex justify-content-between'>
                        <Button as="input" type="button" className='btn-style  m-1 col-lg-6 col-12' value={t('cancel')}
                                onClick={this.resetOrder}/>
                        <Button as="input" type="button" className='btn-style btn-secondary m-1 col-lg-6 col-12'
                                value={t('recharges.buttonPay')} onClick={this.sendOrder}/>
                    </div>}

                    {step === 5 && <> {/*checkout*/}
                        <ProgressBar animated now={this.state.paymentProgress} />
                        <div className='col-12 text-center text-dark small'>{t('recharges.You will be immediately redirected to our secure payment gateway')}</div>
                        {responseCheckout && <Form ref={this.payformRef} id="redsysForm" action="https://sis.redsys.es/sis/realizarPago" method="POST">
                            <Form.Control type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1" />
                            {responseCheckout.Ds_Signature && <Form.Control type="hidden" name="Ds_Signature" value={responseCheckout.Ds_Signature} />}
                            {responseCheckout.Ds_MerchantParameters && <Form.Control type="hidden" name="Ds_MerchantParameters" value={responseCheckout.Ds_MerchantParameters} />}
                            <Button type='submit' className='d-none'/>
                        </Form> }
                    </>}
                </>}

            </div>  {/*--end row */}
        </div> }{/*--box-wrap*/}

        </>}</Translation>)
    }
}

// Componente funcional para pasar navigate como prop
function RechargeWithNavigate(props) {
    const navigate = useNavigate();
    return <Recharge {...props} navigate={navigate}/>;
}

export default RechargeWithNavigate;
