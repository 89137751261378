import React, { useEffect, useCallback } from "react";  // Importa useCallback
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import LanguageSelector from "../../language/LanguageSelector";
import useAuth from '../../hooks/useAuth';
import { Link, NavLink } from 'react-router-dom';
import env from "../../helper/env.js";
import helper from "../../helper/fn.js";

const Header = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const { token, loading, logout } = useAuth();
    // const [checked, setChecked] = useState(localStorage.getItem('theme') === 'dark');

    useEffect(() => {
        if (!loading || !document.body.classList.contains('account')) {
            document.getElementById('root').style.display = 'block';
        }
    }, [loading]);  // El efecto solo se ejecuta cuando `loading` cambia



    // Función para cambiar el tema
    // const changeTheme = useCallback((e) => {
    //     const theme = e.target.checked ? 'dark' : 'light';
    //     document.documentElement.setAttribute('data-theme', theme);
    //     localStorage.setItem('theme', theme);
    //     setChecked(e.target.checked);
    // }, []);

    const toggleNavCollapsed = () => {
        const header = document.getElementById('site-header');
        if (header) header.classList.toggle('active');
    };

    const navRoute = useCallback(() => {
        const $target = document.querySelector('button.navbar-toggler');
        if ($target && !$target.classList.contains('collapsed')) {
            $target.click();
        }
        helper.scrollToTop();
    }, []);


    return (
        <header id="site-header" className="fixed-top">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark stroke">
                    <h1>
                        <NavLink to='/' className="navbar-brand">
                            <span className="logohash"></span>
                            {env.app_name} <span className="logo">{t("header slogan")}</span>
                        </NavLink>
                    </h1>
                    <button
                        onClick={() => toggleNavCollapsed()}
                        className="navbar-toggler collapsed bg-gradient"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarToggler"
                        aria-controls="navbarToggler"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon fa icon-expand fa-bars"></span>
                        <span className="navbar-toggler-icon fa icon-close fa-times"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarToggler">
                        <ul className="navbar-nav d-flex justify-content-between">
                            <li className="nav-item">
                                <NavLink className="nav-link" to='/' onClick={navRoute}>{t("Home")}
                                    <span className="sr-only">(current)</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to='/recharge' onClick={()=> { navRoute(); helper.clearClientStorage(); }}> {t("Send top-up")} </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to='/about' onClick={navRoute}>  {t("About us.name")} </NavLink>

                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to='/contact' onClick={navRoute}>  {t("Contact")} </NavLink>
                            </li>

                          

                            {loading ? (
                                <></>
                            ) : token ? (
                                <>
                                    <li className="nav-item myaccount">
                                        <Dropdown className="d-none d-lg-block">
                                            <Dropdown.Toggle className="btn btn-style btn-primary">
                                                {t("account.my account")} <span className="fa fa-angle-down"></span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item  as={Link} to="/account" className="m-2 btn-hover-custom"  > {t("account.my account")} </Dropdown.Item>
                                                <Dropdown.Item  as={Link} to="/history" className="m-2 btn-hover-custom"  > {t("account.history")} </Dropdown.Item>
                                                <Dropdown.Item  as={Link} to="/beneficiaries" className="m-2 btn-hover-custom"  > {t("account.beneficiaries")} </Dropdown.Item>
                                                <hr className="separate-dropdown-menu"/>
                                                <Dropdown.Item onClick={logout}>{t("login.logout")}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <a className="btn btn-style btn-primary d-lg-none d-block" href="/account" onClick={navRoute} >
                                            {t("account.my account")}
                                        </a>
                                    </li>
                                </>
                            ) : (
                                <>
                                    {location.pathname !== '/signin' && <li className="nav-item">
                                        <a className="nav-link h6" href="/signin">
                                             {t("login.login")}
                                        </a>
                                    </li>}
                                    {/* {location.pathname !== '/signup' && <li className="nav-item">
                                        <a className="btn btn-style btn-primary" href="/signup">
                                            {t("signup")}
                                        </a>
                                    </li>} */}
                                </>
                            )}

                            {/* <li className="nav-item d-none d-lg-block">
                                <LanguageSelector />
                            </li> */}
                        </ul>
                    </div>
                    <div className="mobile-position">
                        <nav className="navigation">
                            <div className="theme-switch-wrapper">
                                {/* <label className="theme-switch" htmlFor="checkbox">
                                    <input type="checkbox" id="checkbox" checked={checked} onChange={changeTheme} />
                                    <div className="mode-container py-1">
                                        <i className="gg-sun"></i>
                                        <i className="gg-moon"></i>
                                    </div>
                                </label> */}
                                <LanguageSelector />
                            </div>
                        </nav>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
