import React from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel, Button, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';

const CustomCarousel = ({ id = '', items, itemkey, provider, onSelect }) => {
  const { t } = useTranslation();

  const renderTooltip = (tooltipText) => (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipText}
    </Tooltip>
  );

  const handleCTA = (item) => {
    if (onSelect) {
      onSelect(item);
    }
  };

  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const itemsPerSlide = 2; // Cambia este valor a 2 si deseas dos columnas
  const chunkedItems = chunkArray(items, itemsPerSlide);
  const colWidth = Math.floor(12 / itemsPerSlide); // Calcula el ancho de la columna

  return (
    <div id={id + '_CustomCarousel'} className={'mt-4 mb-4 ' + itemkey}>
      <Carousel data-bs-theme="dark">
        {chunkedItems.map((chunk, index) => (
          <Carousel.Item key={index}>
            <Row className="align-items-start">
              {chunk.map((item) => (
                <Col xs={12} md={colWidth} key={item[itemkey]} className='pointer' onClick={() => handleCTA(item)}>
                  {item.image ? (
                    <img
                      className="d-block w-100"
                      src={`${item.image}/800x400?text=First slide&bg=f5f5f5`}
                      alt={item.observation}
                    />
                  ) : (
                    <svg
                      className="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                      width="600"
                      height="300"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Third slide"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>{provider.name}</title>
                      <rect width="100%" height="100%" fill="#fff"></rect>
                    </svg>
                  )}
                  <Carousel.Caption>
                    {provider.logo && (
                      <img
                        className="mb-3"
                        src={provider.logo}
                        alt={provider.name}
                        style={{ maxHeight: '25px', width: 'auto' }}
                      />
                    )}
                    {item.observation && (
                      <h6 style={{ height: '2em', maxWidth: '100%' }}>
                        {item.observation}
                      </h6>
                    )}
                    <div className="cta mt-3 mb-5">
                      {item.additional_info ? (
                        <OverlayTrigger
                          trigger={['hover', 'focus', 'click']}
                          placement="top"
                          overlay={renderTooltip(item.additional_info)}
                        >
                          <Button
                            type="button"
                            variant="outline-primary"
                            size="lg"
                            className="mt-3"
                            onClick={() => handleCTA(item)}
                          >
                            ¡{t('I want it')}!
                          </Button>
                        </OverlayTrigger>
                      ) : (
                        <Button
                          type="button"
                          variant="outline-primary"
                          size="lg"
                          className="mt-3"
                          onClick={() => handleCTA(item)}
                        >
                          ¡{t('I want it')}!
                        </Button>
                      )}
                    </div>
                  </Carousel.Caption>
                </Col>
              ))}
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default CustomCarousel;
