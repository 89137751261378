import React from 'react';
import {Translation, withTranslation} from 'react-i18next';
import { t } from "i18next";
import RequireAuth from "../Auth/RequireAuth";
import callServerApi from "../Utils/apiManager";
import withNavigation from '../Utils/withNavigation';

import AutocompletePhoneNumber from "../Utils/autocompletePhoneNumber.js";
import CustomNavDropdown from '../Utils/customNavDropdown.js';
import ResponseAlert from "../Common/ResponseAlert";
import ConfirmationModal from "../Common/ConfirmationModal";
import { Loading } from '../Common/Loading';

import { Dropdown, ToggleButton, ToggleButtonGroup, Button, Nav, Form } from "react-bootstrap";

import helper from "../../helper/fn.js";
import env from "../../helper/env.js";

class Beneficiaries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true, dashboard: (props.dashboard) ? true : false,
            beneficiariesData:[], beneficiary:null, canSave:false,
            responseSuccess: null, responseError: null,
            deleteConfirmation:null,
            form : false,
            providersData:null,
            providerDropdownSelected:null, providerSelected:null,
            optionsView:['list', 'addform'],
            currentView: null,
        };
        this.inputname = React.createRef();
    }

    componentDidMount = () =>{
        this.getBeneficiaries();
    }
    getBeneficiaries = () => {
        const fnNext = (result) => {
            if (result.status ==='ok' && result.code === 200){
                const data = (this.state.dashboard) ? result.data.slice(0, 2) : result.data;
               // const data = [];
                this.setState({
                    beneficiariesData: data,
                    currentView: (data.length===0 && !this.state.dashboard) ? 'addform' : 'list',
                    loading: false
                });
            }
        }
        callServerApi('client/get-beneficiaries', 'GET', {}, true)
            .then(fnNext)
            .catch(error => {
                this.setState({
                    loading: false,
                    errors: error
                });
            });
    }
    handleChangeView = (v) => {
        this.setState({currentView:v});
        switch (v) {
            case 'list':
                this.getBeneficiaries();
                break;
            case 'addform':
                this.resetBeneficiary(true);
                break;
            default:
                break;
        }

    };

    handleEdit = (id) => {
        //load *edit view
        const b = this.state.beneficiariesData.filter(x => Number(x.id) === Number(id))[0];
        console.log('handleEdit',b);
        console.log('handleEdit',  this.state.providersData);
        const provider = (b.provider) ? {provider_code:b.provider, name: b.provider_name, logo:b.logo}: null;
        let prefix = (b.telephone_prefixes &&  b.telephone_prefixes.length > 0) ? b.telephone_prefixes[0] : null;
        prefix.value = prefix.prefix;
        // const plus = (prefix && prefix.prefix && !prefix.prefix.startstartsWith('+')) ? '+' : '';
        // const phoneNumber = (b.phone && prefix) ? b.phone.replace(plus+prefix.prefix,'') : null;
        const phoneNumber = helper.getPhoneNumberWithOutPrefix(prefix.value, b.phone);
        this.setState({
            beneficiary: b,
            providerSelected:provider,
            prefixSelected: prefix,
            phoneNumber: phoneNumber,
            canSave:true
        });
        if (prefix && phoneNumber){
            localStorage.setItem('prefixObj', JSON.stringify(prefix));
            localStorage.setItem('phoneNumber', phoneNumber);
        }
        if (provider) {
            localStorage.setItem('providerObj', JSON.stringify(provider));
        }
        this.handleChangeView('editform');
    }
    handleDelete = () => {
        const id = this.state.deleteConfirmation;
        this.setState({deleteConfirmation:false});
        if (!id) return;
        this.setState({
            loading: true
        });
        const fnDelete = (result) => {
            if (result.status ==='ok' && result.code === 200){
                this.setState(prevState => ({
                    beneficiariesData: prevState.beneficiariesData.filter(beneficiary => beneficiary.id !== id),
                    responseSuccess: result.message
                }));
            }else {
                this.setState({
                    responseError: result.error
                });
            }
            this.setState({
                loading: false
            });
        }
        callServerApi('client/delete-beneficiaries', 'DELETE', {id: id}, true)
            .then(fnDelete)
            .catch(error => {
                this.setState({
                    loading: false,
                    errors: error
                });
            });
    };


    saveBeneficiary = () =>{
        /* *edit providerCode, countryIsos, name, phone */
        const {beneficiary, prefixSelected, phoneNumber,providerSelected} = this.state;
        const d = {
            id: beneficiary.id,
            name: this.inputname.current.value,
            countryIsos: (prefixSelected) ? prefixSelected.country_iso : '',
            phone: helper.patterPhoneNumberToSend(prefixSelected.value, phoneNumber), //(this.state.prefixSelected && this.state.phoneNumber) ? plus + String(this.state.prefixSelected.value) + String(this.state.phoneNumber) : '',
            providerCode: (providerSelected) ? providerSelected.provider_code : '',
        }

        if (!d.name) { this.setState({responseError: t("beneficiaries.Name is missing")});  this.inputname.current.focus() }
        else if (!d.id || !d.phone || !d.providerCode) { this.resetBeneficiary(); }
        else{
            this.setState({
                loading: true
            });
            const fnAfterSave = (result) => {
                if (result.status ==='ok' && result.code === 200){
                    this.setState({responseSuccess: result.message});
                }else {
                    this.setState({responseError: result.error });
                }
                this.setState({loading: false });
                this.handleChangeView('list');
                helper.clearClientStorage();
            }
            callServerApi('client/update-beneficiaries', 'PUT', d, true)
                .then(fnAfterSave)
                .catch(error => {
                    this.setState({
                        loading: false,
                        errors: error
                    });
                });
        }
    }

    saveNewBeneficiary = () =>{
        /* providerCode, countryIsos, name, phone */
        const {prefixSelected, phoneNumber} = this.state;
        const d = {
            name: this.inputname.current.value,
            countryIsos: (this.state.prefixSelected) ? this.state.prefixSelected.country_iso : '',
            phone: helper.patterPhoneNumberToSend(prefixSelected.value, phoneNumber), //(this.state.prefixSelected && this.state.phoneNumber) ? plus + String(this.state.prefixSelected.value) + String(this.state.phoneNumber) : '',
            providerCode: (this.state.providerSelected) ? this.state.providerSelected.provider_code : '',
        }
        if (!d.name) { this.setState({responseError: "Name is missing"});  this.inputname.current.focus() }
        else if (!d.phone || !d.providerCode) { this.resetBeneficiary(); }
        else{
            this.setState({
                loading: true
            });
            const fnAfterSave = (result) => {
                if (result.status ==='ok' && result.code === 200){
                    this.setState({responseSuccess: result.message});
                }else {
                    this.setState({responseError: result.error });
                }
               // this.setState({loading: false });
                //this.handleChangeView('list');
                helper.clearClientStorage();
                this.getBeneficiaries();
            }
            callServerApi('client/new-beneficiaries', 'POST', d, true)
                .then(fnAfterSave)
                .catch(error => {
                    this.setState({
                        loading: false,
                        errors: error
                    });
                });
        }
    }
    resetBeneficiary = (noChangeView) =>{
        this.setState({providerSelected:null,providersData:null,prefixSelected:null, phoneNumber:null});
        if (!noChangeView) this.handleChangeView('list');
        helper.clearClientStorage();
    }

    handleRechage = (data) =>{
        data.beneficiary = this.state.beneficiary;
        helper.sendRecharge(data);
    }
    /*
        oData = {prefixSelected, phoneNumber, providers}
        @AutocompletePhoneNumber
    */
    handlePhoneNumberResult = (oData) => {
        //console.log('handlePhoneNumberResult', oData);
        if (oData.prefixSelected && oData.phoneNumber && oData.providers){
            this.setState({loading:false, prefixSelected:oData.prefixSelected , phoneNumber:oData.phoneNumber});
            this.setState({providersData: oData.providers, title:'recharges.who would you like to top-up', providerSelected:null});
        }else if (oData.prefixSelected && oData.phoneNumber && oData.providerSelected){
            this.setState({
                loading:false, prefixSelected:oData.prefixSelected , phoneNumber:oData.phoneNumber,
                providerSelected: oData.providerSelected,
                canSave:true
            });
        }
    }
    

    /* ------------------------
    * PROVIDERS
    *------------------------*/
    handleSelectProvider = (selected) => {
        const filtered = this.state.providersData.filter(option =>
            option.id === selected.id
        );
        if (filtered && filtered.length > 0){
            this.setState({providerDropdownSelected:filtered[0], canSave:true});
        }
    }
    setProviderSelected = () => {
        const { providersData, providerDropdownSelected } = this.state;
        let selected = null;
        if (providerDropdownSelected){ selected =  providerDropdownSelected;
        }else if (providersData && providersData.length > 0) selected = providersData[0];
        this.setState({providerSelected: selected, canSave:(selected) ? true : false});
    }
    getProviders = (fnNext) => { 
        const {prefixSelected, phoneNumber} = this.state;
        this.setState({loading:true});
        if (prefixSelected && phoneNumber) { 
            this.setState({
                loading: true
            });
            const fnAfter = (result) => { console.log('getProviders', result);
                if (result.status ==='ok' && result.data && result.data.length > 0){
                    this.setState({providersData:result.data});   
                    localStorage.setItem('providerObj', JSON.stringify(result.data));
                }else {
                    this.setState({responseError: result.error });
                }
                this.setState({loading: false });
                if (fnNext) fnNext();
            }
            callServerApi('get-providers?countryIsos='+prefixSelected.country_iso, 'GET')
                .then(fnAfter)
                .catch(error => {
                    this.setState({
                        loading: false,
                        errors: error
                    });
                });
        }else alert('no')
        
    }




    render() {
        const { beneficiariesData, beneficiary,
            prefixSelected,phoneNumber,providersData, providerSelected,currentView,
            deleteConfirmation, responseSuccess, responseError, dashboard, loading} = this.state;
        return (
            <Translation ns={['translation']}>
                { (t) => <>
                    {loading && <Loading/>}
                    {responseSuccess && <ResponseAlert response={responseSuccess} variant="success" />}
                    {responseError && <ResponseAlert response={responseError} variant="danger" />}
                    {deleteConfirmation &&
                        <ConfirmationModal show={true} handleClose={() => this.setState({deleteConfirmation:null}) }
                            handleConfirm={this.handleDelete}
                            modalHeader={t("confirmation")}
                            modalBody={t("beneficiaries.confirm delete")}
                    />}
                    {/* view buttons */}
                    {!dashboard && currentView === 'list' &&
                        <div className="row margin-0 justify-content-end beneficiary-tools">
                            <ToggleButtonGroup type="radio" name="current-view" defaultValue={currentView} className="mb-2 pull-right btn-group col-12 col-lg-6" onChange={this.handleChangeView}>
                                <ToggleButton id="tbg-check-1" value={'list'} className={'first-child ' + (currentView === 'list' ? 'active': '')} >
                                    {t("beneficiaries.title")}
                                </ToggleButton>
                                <ToggleButton id="tbg-check-edit"  value={'editform'} className="d-none"></ToggleButton>
                                <ToggleButton id="tbg-check-2"  value={'addform'} className={(currentView === 'addform' ? 'text-dark': 'text-white')}>
                                    <span className="fa fa-plus"></span> {t("beneficiaries.add")}
                                </ToggleButton>

                            </ToggleButtonGroup>
                        </div>
                    }
                    {currentView === 'list'&& <>
                        { dashboard && <>
                            <div className='d-flex justify-content-between align-items-center mb-2'>
                                <div className='font-weight-bold'>{t("account.beneficiaries")}</div>
                                {beneficiariesData && beneficiariesData.length > 0 && <Nav className="justify-content-right nav">
                                    <Nav.Link href="/beneficiaries">{t("all")} <i className="fa fa-angle-right" aria-hidden="true"></i></Nav.Link>
                                </Nav>}
                            </div>
                            { beneficiariesData && beneficiariesData.length === 0 &&
                               <Nav.Link className="btn btn-outline-primary mt-lg-5" href="/beneficiaries">{t("beneficiaries.add")} <i className="fa fa-angle-right" aria-hidden="true"></i></Nav.Link>
                            }
                        </>}
                        { !dashboard && <div className='d-flex justify-content-left align-items-center mb-2'>
                            <Nav className="justify-content-right nav">
                                <Nav.Link href="/account" className='d-flex align-items-center'>
                                        <div className='icon-box mr-1'><span className="fa fa-cubes"></span> </div>
                                        <div className='mr-1'><i className="fa fa-angle-left" aria-hidden="true"></i> </div>
                                        {t("account.dashboard")}
                                </Nav.Link>
                            </Nav>
                        </div>}
                        <div className="card-deck m-0 mt-3 justify-content-center">
                            {beneficiariesData.map(x => (
                                <div className="beneficiary-card card text-center" key={x.id}>
                                    <div className="card-header bg-transparent border-0 d-flex justify-content-between align-items-center pb-0 pr-0 pl-0">
                                        <div className="flag d-flex justify-content-center align-items-center rounded-circle p-0 cicle-provider-beneficiaries"
                                                style={{
                                                    backgroundImage: `url(${env.img_basepath}flags/1x1/${x.country.toLowerCase()}.svg)`,
                                                }}>
                                        </div>
                                        <h4 className="card-title m-0">{x.name}</h4>
                                        {!dashboard && <div className='beneficiaries-tools'>
                                            <Dropdown>
                                                <Dropdown.Toggle className="btn-dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" className="bi bi-three-dots-vertical"
                                                            viewBox="0 0 16 16">
                                                        <path
                                                            d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                                    </svg>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item as="button"
                                                        onClick={() => this.handleEdit(x.id)}
                                                        className="m-2 btn-hover-custom">
                                                        {t("beneficiaries.edit")}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as="button"
                                                        onClick={() => this.setState({deleteConfirmation:x.id}) }
                                                        className="m-2 btn-hover-custom">
                                                        {t("beneficiaries.delete")}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>}
                                    </div>
                                    <div className="card-body">
                                        <div className="text-center">
                                            <p className="font-weight-bold mb-2">{x.phone}</p>
                                            {x.logo && <div><img src={x.logo} className="logo" alt={x.name}/> </div>}
                                        </div>
                                    </div>
                                    <div className="card-footer border-0 pt-0 mb-3">
                                        <button className="btn btn-outline-secondary font-size-1"
                                            onClick={() => this.handleRechage(x)}>
                                            {t("Recharge")}
                                        </button>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </>}
                    {currentView === 'addform' && <>
                        <div className='container bg-white m-4 p-3 map-content-9'>
                            <h3>{t("beneficiaries.add")}:</h3>
                            <Form className="beneficiary-form mt-5">
                                <Form.Group className="mb-3">
                                    <Form.Label className='font-weight-bold'>{t('beneficiaries.name')}:</Form.Label>
                                    <Form.Control autoFocus ref={this.inputname} type="text" placeholder={t('beneficiaries.name')} required />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label className='font-weight-bold'>{t('beneficiaries.contact telephone number')}:</Form.Label>

                                    <AutocompletePhoneNumber resetStorage={true}
                                        onResetPhoneNumber={this.resetBeneficiary}
                                        placeholder={t('beneficiaries.contact telephone number')}
                                        btnTitle={t('beneficiaries.verify number')}
                                        handleResult={this.handlePhoneNumberResult}
                                        onChangeFunction={()=>this.setState({providersData:null})}/>
                                        { providersData && providersData.length && !providerSelected && <>
                                            <CustomNavDropdown id="providers" title="recharges.select the provider"
                                                items={providersData} itemkey="id" itemsearch={'name'} cols={['logo', 'name']}
                                                onSelect = {this.handleSelectProvider} />
                                            <div className='cta'>
                                                <Button type="button" className='btn-secondary btn-style' onClick={this.setProviderSelected}> {t('next')}</Button>
                                            </div>
                                        </>}

                                    {providerSelected && <>
                                        <div className='text-center'>
                                            <div className='d-flex mb-5 align-items-center'>
                                                {providerSelected.logo && <img width={'100px'} src={providerSelected.logo} alt={providerSelected.name} className="border-0 img-thumbnail mr-2"/> }
                                                <b>{providerSelected.name }</b>
                                            </div>
                                            <div className='valid-phone text-center m-auto '>
                                                <Button type="button" className='btn-style btn-valid col-12 col-lg-6 btn btn-secondary'  onClick={()=>{
                                                    this.resetBeneficiary();
                                                    this.setState({currentView: 'list'})
                                                }}>{t('cancel')} </Button>
                                                {this.state.canSave && prefixSelected && phoneNumber && providerSelected &&
                                                    <Button type="button" className='mt-3 m-lg-3 btn-style btn-valid col-12 col-lg-6 btn btn-primary' onClick={this.saveNewBeneficiary} > {t('save')} </Button>
                                                }
                                            </div>
{/* 

                                            <div className='cta'>
                                                <Button type="button" className='btn-style' onClick={this.resetBeneficiary}> {t('cancel')} </Button>
                                                <Button type="button" className='btn-secondary btn-style' onClick={this.saveNewBeneficiary} > {t('save')} </Button>
                                            </div> */}
                                        </div>
                                    </>}
                                </Form.Group>
                               
                            </Form>
                        </div>
                    </>}
                    {currentView === 'editform' && beneficiary && <>
                        <div className='container bg-white m-4 p-3 map-content-9'>
                            <h3>{t("beneficiaries.edit title")}: <b>{beneficiary.name}</b></h3>
                            <Form className="beneficiary-form mt-5">
                                <Form.Group className="mb-3">
                                    <Form.Label className='font-weight-bold'>{t('contact.name')}:</Form.Label>
                                    <Form.Control autoFocus ref={this.inputname} type="text" placeholder={t('contact.name')} required defaultValue = {beneficiary.name}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    
                                    { !phoneNumber && <>
                                        <Form.Label className='font-weight-bold'>{t('beneficiaries.phone')}:</Form.Label>
                                            <AutocompletePhoneNumber
                                                placeholder={t('beneficiaries.contact telephone number')}
                                                onChangeTitle ='beneficiaries.verify number'
                                                onChangeFunction = {() => { this.setState({canSave:false, providerSelected:null}) }}
                                                handleResult={this.handlePhoneNumberResult}/>
                                    </>  }
                                       
                                    {phoneNumber && providersData && providersData.length && !providerSelected && <>
                                        <Form.Label className='font-weight-bold'>{t('recharges.select the provider')}:</Form.Label>
                                        <CustomNavDropdown id="providers" title="recharges.select the provider" items={providersData} itemkey="id" itemsearch={'name'} cols={['logo', 'name']}
                                        onSelect = {this.handleSelectProvider} />

                                        <div className='valid-phone text-center m-auto '>
                                                <Button type="button" className='m-3 btn-style btn-valid col-12 col-lg-6 btn btn-primary' onClick={this.setProviderSelected} > {t('save')} </Button>
                                        </div>
                                    </>}
                                </Form.Group>
                                <div className='row justify-content-between align-items-center'>
                                    { prefixSelected && phoneNumber && providerSelected && <>
                                        <div className='col-12 col-lg-6'>
                                            <div>
                                                <div className="flag d-flex justify-content-center align-items-center rounded-circle p-0 cicle-provider-beneficiaries"
                                                    style={{
                                                        backgroundImage: `url(${env.img_basepath}flags/1x1/${prefixSelected.country_iso.toLowerCase()}.svg)`,
                                                    }}>
                                                </div>

                                                <b>+{prefixSelected.prefix} {phoneNumber}</b>
                                            </div>
                                            <div  className='text-end text-lg-start'>
                                                <button type="button" className='receipe btn btn-sm lh-1 btn-primary text-white'
                                                    onClick={()=>{
                                                        helper.clearClientStorage();
                                                        this.setState({canSave:false, providerSelected:null, phoneNumber:null,prefixSelected:false});
                                                    }}>
                                                    {t('edit')}
                                                </button>
                                            </div>
                                        </div>
                                    </>}
                                    {providerSelected && <>
                                        <div className='col-12 col-lg-6'>
                                            <div>
                                                {providerSelected.logo && <img width={'150px'} src={providerSelected.logo} alt={providerSelected.name} className="border-0 img-thumbnail"/> }
                                                {!providerSelected.logo && <b>{providerSelected.name }</b>}
                                            </div>
                                            <div className='text-end text-lg-start mb-3'>
                                                <button type="button" className='btn btn-sm lh-1 btn-primary text-white'
                                                    onClick={()=>{
                                                        this.getProviders(() =>  this.setState({canSave:false, providerSelected:null}) ); 
                                                    }}>
                                                    {t('edit')}
                                                </button>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                                <div className='valid-phone text-center m-auto '>
                                    <Button type="button" className='btn-style btn-valid col-12 col-lg-6 btn btn-secondary'  onClick={()=>{
                                        this.resetBeneficiary();
                                        this.setState({currentView: 'list'})

                                    }}>{t('cancel')} </Button>
                                    {this.state.canSave && prefixSelected && phoneNumber && providerSelected &&
                                        <Button type="button" className='mt-3 m-lg-3 btn-style btn-valid col-12 col-lg-6 btn btn-primary' onClick={this.saveBeneficiary} > {t('save')} </Button>
                                    }
                                </div>
                            </Form>
                        </div>
                    </>}
                </>
                }
            </Translation>
        )
    }
}

export default withTranslation()(withNavigation(RequireAuth(Beneficiaries)));
